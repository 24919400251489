import type { PropsWithChildren } from 'react'
import SkeletonElement from 'src/components/skeletons/SkeletonElement'

import styles from './navigation-category-skeleton.module.scss'

interface Props {
  bordered?: boolean
  sectioned?: boolean
  showActions?: boolean
  variant?: 'wide' | 'default'
  loading: boolean
}

const NavigationSkeleton = ({
  bordered,
  sectioned = false,
  variant = 'default',
  loading,
  children,
}: PropsWithChildren<Props>) => {
  return loading ? (
    <div className={styles.navigationCategoryContainer}>
      {Array.from({ length: 7 }, (_, index) => (
        <div
          key={String(index)}
          data-store-navigation-card-skeleton
          className={styles.navigationCardSkeleton}
          data-bordered={bordered}
          data-variant={variant}
        >
          <div
            data-navigation-card-skeleton-image
            className={styles.skeletonImage}
            data-sectioned={sectioned}
          >
            <SkeletonElement type="image" />
          </div>
          <div data-navigation-card-skeleton-content>
            <SkeletonElement type="text" />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <>{children}</>
  )
}

export default NavigationSkeleton
