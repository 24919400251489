import 'src/styles/pages/homepage.scss'

import type { HomePageQueryQuery } from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GatsbySeo, JsonLd } from 'gatsby-plugin-next-seo'
import { get, set } from 'idb-keyval'
import { useEffect } from 'react'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import Newsletter from 'src/components/sections/Newsletter'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageView'
import { useSession } from 'src/sdk/session'
import { mark } from 'src/sdk/tests/mark'
import RenderCMS from 'src/components/RenderCMS'

import { storeUrl } from '../../store.config'

export type Props = PageProps<HomePageQueryQuery>

function Page({ data }: Props) {
  const { site, cmsHome } = data

  const session = useSession()
  const title = site?.siteMetadata?.title ?? ''
  const siteUrl = `${site?.siteMetadata?.siteUrl}`

  const { sendpageViewEvent } = usePageViewEvent()

  useEffect(() => {
    const HAS_WINDOW =
      typeof window !== 'undefined' && typeof window !== undefined

    if (HAS_WINDOW) {
      const searchParams = new URLSearchParams(window.location.search)
      const orderFormFromCheckout = searchParams.get('orderFormFromCheckout')

      if (orderFormFromCheckout !== null && orderFormFromCheckout.length > 0) {
        get('fs::cart').then((val) => {
          const newOrderFormFromCheckout = val

          newOrderFormFromCheckout.id = orderFormFromCheckout

          set('fs::cart', newOrderFormFromCheckout)
        })
      }
    }

    sendpageViewEvent({
      location: siteUrl,
      page: 'HOME',
      title,
      originalLocation: storeUrl,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <GatsbySeo
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        language={session.locale}
        canonical={siteUrl}
        openGraph={{
          type: 'website',
          url: siteUrl,
          title: title ?? '',
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <JsonLd
        json={{
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url: siteUrl,
          potentialAction: {
            '@type': 'SearchAction',
            target: `${siteUrl}/s/?q={search_term_string}`,
            'query-input': 'required name=search_term_string',
          },
        }}
      />
      <RenderCMS sections={cmsHome?.sections} />
      <StockAvailable icon />
      <Newsletter
        title="Recibí promociones y novedades"
        subtitle="Ingresá tu correo electrónico"
      />
    </>
  )
}

export const querySSG = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
    cmsHome {
      sections {
        data
        name
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)

export { Head } from 'src/components/common/Head'
