import { useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import type { Props } from 'src/sdk/analytics/hooks/useSelectPromotion'
import { useSelectPromotion } from 'src/sdk/analytics/hooks/useSelectPromotion'

/**
 * @export
 * @description
 * @param {string}
 * @returns {void}
 * @example
 */

export const useViewPromotion = () => {
  const { sendItemPromotionEvent } = useSelectPromotion()
  const { ref, inView } = useInView({ threshold: 1 })

  const sendViewPromotionEvent = useCallback(
    ({ href, title, nameEvent, id }: Props) => {
      if (typeof window === 'undefined' || !window || !inView) {
        return null
      }

      const hasEvent = window.dataLayer.some(
        (item) =>
          item?.event === 'view_promotion' &&
          item?.ecommerce.item_list_id === id
      )

      if (!hasEvent) {
        return null
      }

      return () =>
        sendItemPromotionEvent({
          href,
          title,
          nameEvent,
          id,
        })
    },
    [inView, sendItemPromotionEvent]
  )

  return { sendViewPromotionEvent, ref, inView }
}
