import { useEffect } from 'react'
import { Image } from 'src/components/ui/Image'
import { useSelectPromotion } from 'src/sdk/analytics/hooks/useSelectPromotion'
import { useMediaQuery } from 'react-responsive'
import { Link } from '@faststore/ui'

import { useViewPromotion } from '../../hooks/useViewPromotion'

const DynamicImages = ({
  imageWidth,
  imageHeight,
  imageValues,
  className,
  valueLoading,
}: TImages) => {
  const { sendItemPromotionEvent } = useSelectPromotion()

  const { sendViewPromotionEvent, ref, inView } = useViewPromotion()

  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)',
  })

  useEffect(() => {
    sendViewPromotionEvent({ ...imageValues, nameEvent: 'view_promotion' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const width = isDesktop ? imageWidth.desktop : imageWidth.mobile
  const height = isDesktop ? imageHeight.desktop : imageHeight.mobile

  return (
    <div className={className}>
      <Link
        aria-label={imageValues.title ?? 'Link'}
        href={imageValues.link?.toLocaleLowerCase() ?? '/'}
        onClick={() =>
          sendItemPromotionEvent({
            ...imageValues,
            nameEvent: 'select_promotion',
          })
        }
      >
        <Image
          loading={valueLoading ?? 'lazy'}
          src={imageValues.href ?? '/'}
          alt={imageValues.title}
          width={width}
          height={height}
          ref={ref}
          options={{ fitIn: true }}
        />
      </Link>
    </div>
  )
}

export default DynamicImages
